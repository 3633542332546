// Base
import * as React from 'react'

// Components
import { Box, Container, Tab, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CardList from '../CardList'

const TabContainer = ({ content }) => {
  const [value, setValue] = React.useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ my: { xs: 6, md: 8 } }}>
      <Container
        disableGutters
        maxWidth='xl'
        sx={{
          textAlign: 'center',
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label='lab API tabs example'>
              <Tab label='Buyers' data-text='Buyers' value='1' />
              <Tab label='Suppliers' data-text='Suppliers' value='2' />
            </TabList>
            <TabPanel
              sx={{ backgroundColor: content?.backgroundColor, borderRadius: '0 12px 12px 12px', py: 5, px: 3 }}
              value='1'
            >
              <Typography variant='h2' sx={{ color: 'bluishGrey.nine', mb: 3 }}>
                Buyers
              </Typography>
              <Typography variant='body1' sx={{ mb: 3 }}>
                Our network of professional buyers expands over 120 countries and represents a retail value of more than
                70 bn USD. You can find over 15,000 companies on Foursource.
              </Typography>
              <Box sx={{ mx: { xs: -2, xl: 0 } }}>
                <CardList content={content.buyers} />
              </Box>
            </TabPanel>
            <TabPanel
              sx={{ backgroundColor: content?.backgroundColor, borderRadius: '0 12px 12px 12px', py: 5, px: 3 }}
              value='2'
            >
              <Typography variant='h2' sx={{ color: 'bluishGrey.nine', mb: 3 }}>
                Suppliers
              </Typography>
              <Typography variant='body1' sx={{ mb: 3 }}>
                Our network of verified apparel manufacturers and fabric suppliers come from more than 90 countries and
                range across product categories and qualities. Excluding intermediates.
              </Typography>
              <Box sx={{ mx: { xs: -2, xl: 0 } }}>
                <CardList content={content.suppliers} />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Box>
  )
}
export default TabContainer
