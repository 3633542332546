import * as React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Cover from '../components/Cover'
import TabContainer from '../components/TabContainer'
import Testimonials from '../components/Testimonials'
import Cta from '../components/Cta'

// Content
import { contentReferences } from '../content/references'
import { ctaContent } from '../content/cta'

const ReferencesPage = () => {
  const references = contentReferences

  return (
    <Layout headerWhite={true}>
      <Cover content={references.cover} />
      <TabContainer content={references.tabContainer} />
      <Testimonials />
      <Cta content={ctaContent} />
    </Layout>
  )
}

export const Head = ({ location }) => (
  <Seo
    title='References'
    description='A global and diverse network, united by a common industry expands over 120 countries'
    pathname={location.pathname}
  />
)

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ReferencesPage
