import * as React from 'react'

// Components
import CompanyCard from '../components/CompanyCard'

// Images
import background from '../assets/images/covers/references-background.svg'

// Buyers
import logoCarrefour from '../assets/images/references/carrefour.jpg'
import logoCentric from '../assets/images/references/centric-brands.jpg'
import logoChargeurs from '../assets/images/references/chargeurs.jpg'
import logoChelmsford from '../assets/images/references/chelmsford-safety-suppliers-ltd.jpg'
import logoChildren from '../assets/images/references/children-worldwide-fashion.jpg'
import logoCrew from '../assets/images/references/crew-clothing-company.jpg'
import logoDoubleTwo from '../assets/images/references/double-two.jpg'
import logoEngbers from '../assets/images/references/engbers.jpg'
import logoHappySocks from '../assets/images/references/happy-socks.jpg'
import logoHarperWilde from '../assets/images/references/harper-wilde.jpg'
import logoBoss from '../assets/images/references/hugo-boss.jpg'
import logoJako from '../assets/images/references/jako.jpg'
import logoKatag from '../assets/images/references/katag-ag.jpg'
import logoLaredoute from '../assets/images/references/la-redoute.jpg'
import logoSinsay from '../assets/images/references/lpp_sinsay_1.jpg'
import logoMaison from '../assets/images/references/maison-123.jpg'
import logoMammut from '../assets/images/references/mammut.jpg'
import logoMango from '../assets/images/references/mango.jpg'
import logoMelon from '../assets/images/references/melon-fashion-group.jpg'
import logoMontane from '../assets/images/references/montane.jpg'
import logoPaul from '../assets/images/references/paul-andrew.jpg'
import logoPlayshoes from '../assets/images/references/playshoes.jpg'
import logoPockies from '../assets/images/references/pockies.jpg'
import logoPresentTeam from '../assets/images/references/the-present-team.jpg'
import logoPrimark from '../assets/images/references/primark.jpg'
import logoRalphLauren from '../assets/images/references/ralph-lauren_1.jpg'
import logoRovitex from '../assets/images/references/rovitex.jpg'
import logoScandia from '../assets/images/references/scandia.jpg'
import logoSeeHerWork from '../assets/images/references/see-her-work.jpg'
import logoSkinEast from '../assets/images/references/ski-the-east_1.jpg'
import logoSport2000 from '../assets/images/references/sport-2000.jpg'
import logoTristyle from '../assets/images/references/tristyle-mode-gmbh.jpg'
import logoVanGennip from '../assets/images/references/van-gennip-textiles.jpg'
import logoVegotec from '../assets/images/references/vegotec-international.jpg'
import logoWoom from '../assets/images/references/woom-gmbh.png'

// Suppliers
import logoEastman from '../assets/images/references/eastman-exports-global-clothing-pvt-ltd-.jpg'
import logoEsstee from '../assets/images/references/esstee-exports.jpg'
import logoPearlGlobal from '../assets/images/references/pearl-global.jpg'
import logoRuby from '../assets/images/references/ruby-logo.jpg'
import logoBateks from '../assets/images/references/bateks-logo-crop-u1294.jpg'
import logoEdsa from '../assets/images/references/edsa_o.jpg'
import logo3F from '../assets/images/references/3f-tekstil.jpg'
import logoTexim from '../assets/images/references/texim.jpg'
import logoViva from '../assets/images/references/viva-sports_n.jpg'
import logoDelta from '../assets/images/references/delta-garment.jpg'
import logoGulAhmed from '../assets/images/references/gul-ahmed.jpg'
import logoGSsweaters from '../assets/images/references/gs-sweaters.jpg'
import logoNeedle from '../assets/images/references/needle_n.jpg'
import logoOlympic from '../assets/images/references/olympic-ashion-logo-2.jpg'
import logoDJ from '../assets/images/references/guangzhou-weixin-germent-co.jpg'
import logoSuxing from '../assets/images/references/suxing.jpg'
import logoWonders from '../assets/images/references/wonders-1.jpg'
import logoIsland from '../assets/images/references/island-cosmos.jpg'
import logoReplica from '../assets/images/references/replica-malhas-confeccoes.jpg'
import logoAurora from '../assets/images/references/aurora-investiments-global.jpg'
import logoQTNP from '../assets/images/references/qtnp.jpg'
import logoJR from '../assets/images/references/j-r-enterprises.jpg'
import logoNaga from '../assets/images/references/naga-garments.jpg'
import logoSweetGirl from '../assets/images/references/sweet-girl.jpg'
import logoTalApparel from '../assets/images/references/tal-apparel.jpg'
import logoTunisian from '../assets/images/references/tunisian-apparel.jpg'
import logoDS from '../assets/images/references/ds-fashion.jpg'
import logoConFi from '../assets/images/references/con-fi-fashion-service.png'
import logoWondersF from '../assets/images/references/wonders.jpg'
import logoShnidertex from '../assets/images/references/shnidertex.jpg'
import logoWorldWA from '../assets/images/references/world-wide-apparel.jpg'
import logoProStandard from '../assets/images/references/prostandard.jpg'
import logoUBase from '../assets/images/references/ubase.jpg'
import logoNemo from '../assets/images/references/nemo-sewing-factory.jpg'
import logoMotives from '../assets/images/references/motives-international.jpg'

export const contentReferences = {
  cover: {
    background: background,
    title: 'Our Members',
    text: 'A global and diverse network, united by a common industry.',
    cta: {
      text: 'Request a demo',
      url: `https://demodesk.com/select/foursource-group-gmbh/choose-business-type`,
      external: true,
    },
  },
  tabContainer: {
    backgroundColor: 'bluishGrey.two',
    buyers: {
      component: <CompanyCard />,
      items: [
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoCarrefour,
            borderRadius: '8px',
          },
          name: 'Carrefour',
          caption: 'Hong Kong',
          url: 'https://www.carrefour.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoCentric,
            borderRadius: '8px',
          },
          name: 'Centric',
          caption: 'United States',
          url: 'https://www.centricbrands.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoChargeurs,
            borderRadius: '8px',
          },
          name: 'Chargeurs',
          caption: 'Germany',
          url: 'https://www.chargeurs-pcc.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoChelmsford,
            borderRadius: '8px',
          },
          name: 'Chelmsford',
          caption: 'United Kingdom',
          url: 'https://www.chelmsfordsafety.co.uk/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoChildren,
            borderRadius: '8px',
          },
          name: 'Children',
          caption: 'France',
          url: 'https://www.groupecwf.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoCrew,
            borderRadius: '8px',
          },
          name: 'Crew',
          caption: 'United Kingdom',
          url: 'https://www.crewclothing.co.uk/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoDoubleTwo,
            borderRadius: '8px',
          },
          name: 'DoubleTwo',
          caption: 'United Kingdom',
          url: 'https://www.doubletwo.co.uk/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoEngbers,
            borderRadius: '8px',
          },
          name: 'Engbers',
          caption: 'Germany',
          url: 'https://www.engbers.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoHappySocks,
            borderRadius: '8px',
          },
          name: 'HappySocks',
          caption: 'Sweden',
          url: 'https://www.happysocks.com/de/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoHarperWilde,
            borderRadius: '8px',
          },
          name: 'HarperWilde',
          caption: 'United States',
          url: 'https://harperwilde.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoBoss,
            borderRadius: '8px',
          },
          name: 'Boss',
          caption: 'Germany',
          url: 'https://www.hugoboss.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoJako,
            borderRadius: '8px',
          },
          name: 'Jako',
          caption: 'Germany',
          url: 'https://www.jako.de/en/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoKatag,
            borderRadius: '8px',
          },
          name: 'Katag',
          caption: 'Germany',
          url: 'https://katag.net/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoLaredoute,
            borderRadius: '8px',
          },
          name: 'Laredoute',
          caption: 'France',
          url: 'https://www.laredoute.com/de/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoSinsay,
            borderRadius: '8px',
          },
          name: 'Sinsay',
          caption: 'Poland',
          url: 'https://www.lppsa.com/en',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoMaison,
            borderRadius: '8px',
          },
          name: 'Maison',
          caption: 'France',
          url: 'https://www.maison123.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoMammut,
            borderRadius: '8px',
          },
          name: 'Mammut',
          caption: 'Switzerland',
          url: 'https://www.mammut.com/de/de/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoMango,
            borderRadius: '8px',
          },
          name: 'Mango',
          caption: 'Spain',
          url: 'https://mango.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoMelon,
            borderRadius: '8px',
          },
          name: 'Melon',
          caption: 'Russia',
          url: 'https://www.melonfashion.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoMontane,
            borderRadius: '8px',
          },
          name: 'Montane',
          caption: 'United Kingdom',
          url: 'https://www.montane.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoPaul,
            borderRadius: '8px',
          },
          name: 'Paul',
          caption: 'United Kingdom',
          url: 'https://paul-andrew.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoPlayshoes,
            borderRadius: '8px',
          },
          name: 'Playshoes',
          caption: 'Germany',
          url: 'https://www.playshoes.de/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoPockies,
            borderRadius: '8px',
          },
          name: 'Pockies',
          caption: 'Netherlands',
          url: 'https://www.pockies.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoPresentTeam,
            borderRadius: '8px',
          },
          name: 'PresentTeam',
          caption: 'Greece',
          url: 'https://www.present-team.eu/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoPrimark,
            borderRadius: '8px',
          },
          name: 'Primark',
          caption: 'United Kingdom',
          url: 'https://www.primark.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoRalphLauren,
            borderRadius: '8px',
          },
          name: 'RalphLauren',
          caption: 'Hong Kong',
          url: 'https://www.ralphlauren.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoRovitex,
            borderRadius: '8px',
          },
          name: 'Rovitex',
          caption: 'Brazil',
          url: 'https://www.rovitex.com.br/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoScandia,
            borderRadius: '8px',
          },
          name: 'Scandia',
          caption: 'Netherlands',
          url: 'https://www.scandiagear.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoSeeHerWork,
            borderRadius: '8px',
          },
          name: 'SeeHerWork',
          caption: 'United States',
          url: 'https://seeherwork.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoSkinEast,
            borderRadius: '8px',
          },
          name: 'SkinEast',
          caption: 'United States',
          url: 'https://www.skitheeast.net/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoSport2000,
            borderRadius: '8px',
          },
          name: 'Sport2000',
          caption: 'Germany',
          url: 'https://www.sport2000.de/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoTristyle,
            borderRadius: '8px',
          },
          name: 'Tristyle',
          caption: 'Germany',
          url: 'https://www.tristylegroup.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoVanGennip,
            borderRadius: '8px',
          },
          name: 'VanGennip',
          caption: 'Netherlands',
          url: 'https://www.vangenniptextiles.com/nl/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoVegotec,
            borderRadius: '8px',
          },
          name: 'Vegotec',
          caption: 'Belgium',
          url: 'https://www.vegotex.com/brands',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoWoom,
            borderRadius: '8px',
          },
          name: 'Woom',
          caption: 'Austria',
          url: 'https://woombikes.com/en_US/',
          external: true,
        },
      ],
      button: {
        text: 'Join now',
        href: 'SIGNUP',
        size: 'small',
        variant: 'contained',
        sx: { mt: 3, mb: 2 },
      },
    },
    suppliers: {
      component: <CompanyCard />,
      items: [
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoEastman,
            borderRadius: '8px',
          },
          name: 'Eastman',
          caption: 'India',
          url: 'https://www.eastmanexports.com',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoEsstee,
            borderRadius: '8px',
          },
          name: 'Esstee',
          caption: 'India',
          url: 'https://www.essteeexports.com',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoPearlGlobal,
            borderRadius: '8px',
          },
          name: 'Pearl Global',
          caption: 'India',
          url: 'https://www.pearlglobal.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoRuby,
            borderRadius: '8px',
          },
          name: 'Ruby',
          caption: 'India',
          url: 'https://rubyapparels.com',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoBateks,
            borderRadius: '8px',
          },
          name: 'Bateks',
          caption: 'Turkey',
          url: 'https://www.bateksicgiyim.com.tr/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoEdsa,
            borderRadius: '8px',
          },
          name: 'Edsa',
          caption: 'Turkey',
          url: 'https://www.edsa.com.tr/tr/index.html',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logo3F,
            borderRadius: '8px',
          },
          name: '3F',
          caption: 'Turkey',
          url: 'https://www.3ftextile.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoTexim,
            borderRadius: '8px',
          },
          name: 'Texim',
          caption: 'Turkey',
          url: 'https://www.texim.com.tr/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoViva,
            borderRadius: '8px',
          },
          name: 'Viva',
          caption: 'Pakistan',
          url: 'https://public.foursource.com/profile/manufacturers/vivo-sports-wears-bb33ea51c05b787ce1b026f59bd2f3e9',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoDelta,
            borderRadius: '8px',
          },
          name: 'Delta',
          caption: 'Pakistan',
          url: 'https://www.deltagarments.com.pk/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoGulAhmed,
            borderRadius: '8px',
          },
          name: 'GulAhmed',
          caption: 'Pakistan',
          url: 'https://gulahmed.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoGSsweaters,
            borderRadius: '8px',
          },
          name: 'GSsweaters',
          caption: 'Bangladesh',
          url: 'https://www.gssweaters.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoNeedle,
            borderRadius: '8px',
          },
          name: 'Needle',
          caption: 'Bangladesh',
          url: 'https://www.needledropltd.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoOlympic,
            borderRadius: '8px',
          },
          name: 'Olympic',
          caption: 'Bangladesh',
          url: 'https://public.foursource.com/profile/olympic-fashion-limited-db35b1f3d006b037c6acf898da036d58',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoDJ,
            borderRadius: '8px',
          },
          name: 'DJ',
          caption: 'China',
          url: 'https://www.dnjgarment.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoSuxing,
            borderRadius: '8px',
          },
          name: 'Suxing',
          caption: 'China',
          url: 'https://public.foursource.com/profile/purple-changzhou-suxing-century--1905aedab9bf2477edc068a355bba31a',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoWonders,
            borderRadius: '8px',
          },
          name: 'Wonders',
          caption: 'China',
          url: 'https://pro.foursource.com/xiamen-wonders-sport-co-ltd',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoIsland,
            borderRadius: '8px',
          },
          name: 'Island',
          caption: 'Portugal',
          url: 'https://www.islandcosmos.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoReplica,
            borderRadius: '8px',
          },
          name: 'Replica',
          caption: 'Portugal',
          url: 'https://www.replica.com.pt/pt/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoAurora,
            borderRadius: '8px',
          },
          name: 'Aurora',
          caption: 'Vietnam',
          url: 'https://public.foursource.com/profile/aurora-investments-global-limited-43fa7f58b7eac7ac872209342e62e8f1',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoQTNP,
            borderRadius: '8px',
          },
          name: 'QTNP',
          caption: 'Vietnam',
          url: 'https://public.foursource.com/profile/qtnp-garment-jsc-2-9acf1b76f369030270de8f98f84d6707',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoJR,
            borderRadius: '8px',
          },
          name: 'JR',
          caption: 'Romania',
          url: 'https://public.foursource.com/profile/jr-enterprises-srl--ae5e3ce40e0404a45ecacaaf05e5f735',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoNaga,
            borderRadius: '8px',
          },
          name: 'Naga',
          caption: 'Egypt',
          url: 'https://public.foursource.com/profile/naga-garments-co-2-0b96d81f0494fde5428c7aea243c9157',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoSweetGirl,
            borderRadius: '8px',
          },
          name: 'SweetGirl',
          caption: 'Egypt',
          url: 'https://public.foursource.com/profile/sweet-gril-1ecfb463472ec9115b10c292ef8bc986',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoTalApparel,
            borderRadius: '8px',
          },
          name: 'TalApparel',
          caption: 'Hong Kong',
          url: 'https://pro.foursource.com/besfit-company-limited',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoTunisian,
            borderRadius: '8px',
          },
          name: 'Tunisian',
          caption: 'Tunisia',
          url: 'https://www.tunisianapparel.com',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoDS,
            borderRadius: '8px',
          },
          name: 'DS',
          caption: 'Bulgaria',
          url: 'https://public.foursource.com/manufacturers',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoConFi,
            borderRadius: '8px',
          },
          name: 'ConFi',
          caption: 'Italy',
          url: 'https://www.confifashionservice.com',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoWondersF,
            borderRadius: '8px',
          },
          name: 'WondersF',
          caption: 'Poland',
          url: 'https://pro.foursource.com/wonders-fashion-anna-wesoowska',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoShnidertex,
            borderRadius: '8px',
          },
          name: 'Shnidertex',
          caption: 'Indonesia',
          url: 'https://public.foursource.com/profile/shnidertex-7cf65e31e39837a301b0173e2a0701aa',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoWorldWA,
            borderRadius: '8px',
          },
          name: 'WorldWA',
          caption: 'Philippines',
          url: 'https://public.foursource.com/manufacturers',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoProStandard,
            borderRadius: '8px',
          },
          name: 'ProStandard',
          caption: 'Taiwan',
          url: 'https://www.prostandard.com.tw/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoUBase,
            borderRadius: '8px',
          },
          name: 'UBase',
          caption: 'SouthKorea',
          url: 'https://www.ubaseinternational.com/',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoNemo,
            borderRadius: '8px',
          },
          name: 'Nemo',
          caption: 'Latvia',
          url: 'https://nemosewing.eu',
          external: true,
        },
        {
          grid: 5,
          gutters: 3,
          logo: {
            src: logoMotives,
            borderRadius: '8px',
          },
          name: 'Motives',
          caption: 'United States',
          url: 'https://motivesinternational.io/',
          external: true,
        },
      ],
      button: {
        text: 'Join now',
        href: 'SIGNUP',
        size: 'small',
        variant: 'contained',
        sx: { mt: 3, mb: 2 },
      },
    },
  },
}
